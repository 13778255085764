<style scoped lang="less">
.Teacher_Home_Notice {
  background: #fff;
  box-shadow: @shadow;
  min-height: 220px;

  .stu-module-header {
    padding-bottom: 25px;
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }

  .teacher_notice_ul {
    padding: 10px 20px;
    overflow: auto;
    max-height: 147px;
    height: 147px;

    li {
      padding: 10px 0 10px 20px;
      border-bottom: 1px solid @borderBottomColor;
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        width: 8px;
        height: 8px;
        background: #83b2f8;
        border-radius: 50%;
      }
      &:hover {
        p:first-child {
          color: @mainColor;
        }
      }
      p:last-child {
        color: #999;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
  .dialog-div {
    position: relative;
    .dialog-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .dialog-content {
      min-height: 300px;
      overflow-y: auto;
      font-size: 14px;
    }
    .dialog-time {
      position: absolute;
      right: 10px;
      bottom: 0;
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
<style lang="less">
.Teacher_Home_Notice {
  .teacher_home_more_btn {
    i {
      float: right;
    }
  }
}
</style>
<template>
  <div class="Teacher_Home_Notice">
    <div class="stu-module-header">
      <div class="stu-module-title">通知公告</div>
      <el-button
        type="text"
        icon="el-icon-arrow-right"
        class="teacher_home_more_btn"
        @click="more"
      >
        更多
      </el-button>
    </div>
    <ul class="teacher_notice_ul">
      <li
        v-for="(item, index) in notice"
        :key="index"
        @click="handleNoticeItem(item)"
      >
        <p v-html="item.title"></p>
        <p>{{ item.createTime }}</p>
      </li>
    </ul>
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      style="text-align: left"
      title="公告"
    >
      <div v-loading="loading" class="dialog-div">
        <div class="dialog-title" v-html="noticeData.title"></div>
        <div
          class="dialog-content"
          v-html="noticeData.content || '暂无信息'"
        ></div>
        <div class="dialog-time" v-html="noticeData.createTime"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { SelectEduCourseNoticeByPage } from "@/libs/api/teacher/user";
export default {
  name: "Teacher_Home_Notice",
  props: ["queryRoot"],
  data() {
    return {
      loading: false,
      noticeData: {
        title: "",
        content: "",
      },
      dialogVisible: false,
      bo: {
        page: 1,
        pageSize: 2,
        title: "",
        status: null,
        startTime: "",
        endTime: "",
      },
      notice: [],
    };
  },
  mounted() {
    this.queryRoot && this.query();
  },
  methods: {
    query() {
      SelectEduCourseNoticeByPage({
        page: this.bo.page,
        pageSize: this.bo.pageSize,
      }).then((res) => {
        this.notice = res.data.list;
        this.$store.dispatch("setHeaderNotice", this.notice[0].title);
      });
    },
    handleNoticeItem(item) {
      this.dialogVisible = true;
      this.noticeData = item;
      // this.loading = true;
      // this.$axios_supermall
      //   .get("/hnjxjy-core/edunotice/queryById?id=" + item.id)
      //   .then((resp) => {
      //     this.loading = false;
      //     if (resp.data.code == 2000) {
      //       this.noticeData = resp.data.data;
      //     }
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //   });
    },
    more() {
      this.$router.push("notice");
    },
  },
};
</script>