<template>
  <el-row class="Home_User">
    <div class="stu-module-header">
      <div class="stu-module-title">个人信息</div>
      <el-button @click="back" type="primary">返回</el-button>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="first">
        <el-col
            :sm="24"
            :md="12"
            :lg="8"
            v-for="(item, key) in dataInfo"
            :key="key"
            class="item"
        >
          <div class="label">{{ label[key] }}：</div>
          <div class="value">{{ item || "-" }}</div>
        </el-col>
      </el-tab-pane>
      <el-tab-pane label="账号安全" name="second">
        <el-form
            :model="formData"
            :rules="rules"
            size="medium"
            label-width="100px"
            class="t_home_updatePwd_form"
            ref="updatePwd_form"
        >
          <el-form-item label="原始密码" prop="loginPassword">
            <el-input
                type="password"
                v-model="formData.loginPassword"
                clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newLoginPassword1">
            <el-input
                type="password"
                v-model="formData.newLoginPassword1"
                clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="newLoginPassword2">
            <el-input
                type="password"
                v-model="formData.newLoginPassword2"
                clearable
            ></el-input>
          </el-form-item
          >
          <el-form-item size="large">
            <el-button type="primary" @click="submitForm" :loading="loading">
              提交
            </el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import {TEACHER_HOME_ROUTE} from "@/tools/const";
import {REG} from "@/tools/const";
import {UploadLoginPassword} from "../../../../../libs/api/user";

export default {
  name: "home-user",
  data() {
    return {
      activeName: "first",
      label: {
        name: "姓名",
        jobNo: "工号",
        idCard: "身份证",
        education: "学历",
        profDir: "专业方向",
        online: "是否在任",
        phoneNum: "手机号",
        email: "邮箱号",
      },
      dataInfo: {
        name: "演示大学老师",
        jobNo: "002",
        idCard: "210102198612123456",
        education: "",
        profDir: "",
        online: "是",
        phoneNum: "",
        email: "",
      },
      loading: false,
      formData: {
        loginId: null,
        loginPassword: null,
        newLoginPassword1: null,
        newLoginPassword2: null,
      },
      rules: {
        loginPassword: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: "blur",
          },
        ],
        newLoginPassword1: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
          {
            pattern: REG.PASSWORD,
            message: "密码长度为10个字符，需包含大小写字母，数字，特殊字符",
            trigger: "blur",
          },
        ],
        newLoginPassword2: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    queryRoot() {
      return this.$sysPerKey("eduTeacher:query");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.query();
    },
  },
  mounted() {
    this.queryRoot && this.query();
  },
  methods: {
    query() {
      this.$axios_supermall
          .get("/hnjxjy-core/eduIndex/queryTeacherIndexData")
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.dataInfo = resp.data.data;
            }
            let data = (({
                           name,
                           jobNo,
                           idCard,
                           education,
                           profDir,
                           online,
                           phoneNum,
                           email,
                         }) => ({
              name,
              jobNo,
              idCard,
              education,
              profDir,
              online,
              phoneNum,
              email,
            }))(this.dataInfo);
            this.dataInfo = data;
          });
    },
    back() {
      this.$store.dispatch("set_teacher_route_switch", TEACHER_HOME_ROUTE.HOME);
    },
    submitForm() {
      this.$refs["updatePwd_form"].validate((valid) => {
        if (!valid) return;
        this.formData.loginId = this.user.loginId;
        if (this.formData.newLoginPassword1 != this.formData.newLoginPassword2) {
          this.$message.error("两次输入的密码不一致");
          return;
        }
        this.loading = true;
        //todo:修改失败回返回1004，但是token过期也是1004，现在去掉了1004返回登录页逻辑，如果要加此逻辑需要后端更改此接口返回值
        UploadLoginPassword(this.formData).then((res) => {
          if (res.code === 2000) {
            this.$message.success("密码更新成功，请重新登录");
            this.loading = false;
            localStorage.clear();
            this.$router.push("/Online/index");
            this.loading = false;
          }else{
            this.$message.error(res.message);
          }
        })
            .catch((ERR) => {
              this.loading = false;
            });
      });
    },
    resetForm() {
      this.$refs["updatePwd_form"].resetFields();
    },
  },
};
</script>

<style scope lang="less">
.Home_User {
  background: #fff;
  box-shadow: @shadow;
  padding-bottom: 10px;

  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }

  .item {
    display: flex;
    align-items: center;

    div {
      padding: 10px 15px;
    }

    .label {
      width: 132px;
      font-weight: bold;
    }
  }

  .t_home_updatePwd_form {
    max-width: 500px;
    margin-top: 10px;
  }
}
</style>