
<style scoped lang="less">
.Teacher_Home_Course {
  background: #fff;
  box-shadow: @shadow;
  height: calc(100vh - 380px);
  min-height: 527px;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .home_search_course_p {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 105px;
    }
  }
  .teacher_home_course_list {
    padding: 0 20px;
    height: calc(100% - 82px);
    overflow-y: auto;
    .t_course_list_li {
      display: flex;
      align-items: flex-start;
      padding: 20px 0;
      border-bottom: 1px solid #f8f8f8;
      .course_list_image {
        width: 100px;
        min-width: 100px;
        height: 100px;
        border-radius: 4px;
        background: #f5f7fa;
        .image-none {
          border-radius: 4px;
          width: 80px;
          height: 55px;
          margin: 20px auto;
          display: block;
        }
      }
      .course_list_right {
        margin-left: 15px;
        .el-button {
          padding: 8px;
        }
        .l_r_courseName {
          font-weight: 500;
          cursor: pointer;
          &::hover {
            color: @mainColor;
          }
        }
        ul {
          margin: 5px 0 18px;
          li {
            display: inline-block;
            font-size: 13px;
            color: #999;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -6px;
              width: 1px;
              height: 12px;
              background: #dcdfe6;
            }
            padding: 0 6px;
            span {
              color: #82b1f8;
            }
            .orange {
              color: #facf59;
            }
          }
          li:first-child {
            padding-left: 0;
          }
          li:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

</style>
<style lang="less">
.Teacher_Home_Course {
  .teacher_home_course_btn {
    i {
      float: right;
    }
  }
}
</style>
<template>
  <div class="Teacher_Home_Course">
    <div class="stu-module-header">
      <p class="home_search_course_p">
        <span>我的课程</span>
        <el-input
          placeholder="请输入课程名称/课程编号"
          prefix-icon="el-icon-search"
          v-model="info"
          @blur="query"
        >
        </el-input>
      </p>
      <el-button
        class="teacher_home_course_btn"
        icon="el-icon-arrow-right"
        type="text"
        @click="go()"
      >
        更多
      </el-button>
    </div>
    <ul class="teacher_home_course_list" v-loading="loading">
      <li
        v-for="(course, index) in courseData"
        :key="index"
        class="t_course_list_li"
      >
        <el-image :src="course.coverUrl" lazy class="course_list_image">
          <div slot="error" class="image-slot">
            <img class="image-none" src="../../../../assets/logo_top.png" />
            <!-- <i class="el-icon-picture-outline"></i> -->
          </div>
        </el-image>
        <div class="course_list_right">
          <div class="l_r_courseName">{{ course.courseName }}</div>
          <ul>
            <li>
              课程编号：<span>{{ course.courseCode }}</span>
            </li>
            <li>
              学生人数：<span>{{ course.studentNum }}</span>
            </li>
            <li>
              发帖数：<span>{{ course.postNum }}</span>
            </li>
            <li>
              回帖数：<span class="orange">{{ course.replyNum }}</span>
            </li>
            <li>
              在线作业：<span>{{ course.workNum }}</span>
            </li>
            <li>
              在线考试：<span>{{ course.examNum }}</span>
            </li>
          </ul>

          <div
            v-if="
              course.courseType === Number(ctype.T1) ||
              course.courseType === Number(ctype.T2)
            "
          >
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '在线答疑')"
              icon="el-icon-question"
            >
              在线答疑
            </el-button>
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '学习导航')"
              icon="el-icon-s-promotion"
            >
              学习导航
            </el-button>
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '时间安排')"
              icon="el-icon-time"
            >
              时间安排
            </el-button>
          </div>
          <div v-else>
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '课程管理')"
              icon="el-icon-reading"
            >
              课程管理
            </el-button>
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '在线答疑')"
              icon="el-icon-question"
            >
              在线答疑
            </el-button>
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '题库管理')"
              icon="el-icon-collection"
            >
              题库管理
            </el-button>
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '在线作业')"
              icon="el-icon-document"
            >
              在线作业
            </el-button>
            <el-button
              type="primary"
              @click="handleBtnGroup(course, '在线考试')"
              icon="el-icon-document-checked"
            >
              在线考试
            </el-button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { COURSE_TYPE } from "@/tools/const";
export default {
  name: "Teacher_Home_Course",
  props: ["queryRoot"],
  data() {
    return {
      info: "",
      loading: false,
      // https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg
      // https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg
      // https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg
      // https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg
      // https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg
      // https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg
      // https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg
      searchCourse: "",
      courseData: [],
    };
  },
  computed: {
    ctype() {
      return COURSE_TYPE;
    },
  },
  mounted() {
    this.queryRoot && this.query();
  },
  methods: {
    go() {
      this.$router.push(`/teacher/course`);
    },
    query() {
      this.loading = true;
      this.$axios_supermall
        .get(
          "/hnjxjy-core/teacherCourse/list?page=1&pageSize=10&info=" + this.info
        )
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.loading = false;
            this.courseData = resp.data.data.list;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleBtnGroup(item, type) {
      this.routerSwitch(item.courseType);
      this.$store.dispatch("set_t_course", item);
      let path = "";
      switch (type) {
        case "课程管理":
          path = "course/manage";
          break;
        case "在线答疑":
          path = "question";
          break;
        case "题库管理":
          path = "course/manage";
          break;
        case "在线作业":
          path = "work/online";
          break;
        case "在线考试":
          path = "exam/online";
          break;
        case "学习导航":
          path = "study/nav";
          break;
        case "时间安排":
          let t = item.courseType;
          path = t === 1 ? "time/arrange" : "time/big/arrange";
          break;
        default:
          break;
      }
      this.$router.push(`/teacher/t/${path}`);
    },
    // 切换菜单
    routerSwitch(type) {
      type = type + "";
      // 毕业大作业
      if (type === COURSE_TYPE.T2) {
        this.$store.dispatch("set_teacher_menu_switch", COURSE_TYPE.T2);
      } else if (type === COURSE_TYPE.T1) {
        // 毕业论文（设计）
        this.$store.dispatch("set_teacher_menu_switch", COURSE_TYPE.T1);
      } else {
        this.$store.dispatch("set_teacher_menu_switch", COURSE_TYPE.T0);
      }
    },
  },
};
</script>
