<template>
  <div class="Teacher_Home_ServiceSupport">
    <div class="stu-module-title">学生服务与支持</div>
    <el-row class="content">
      <el-col :span="24" class="items">
        <p @click="handleQA">
          <i class="el-icon-folder"></i>
          <span>帮助文档</span>
        </p>
      </el-col>
      <el-col :span="24" class="items">
        <p @click="handleQA">
          <i class="el-icon-reading"></i>
          <span>网上图书馆</span>
        </p>
      </el-col>
      <el-col :span="24" class="items">
        <p @click="handleQA">
          <i class="el-icon-user"></i>
          <span>网上自习室</span>
        </p>
      </el-col>
      <el-col :span="24" class="items">
        <p @click="handleQA">
          <i class="el-icon-menu"></i>
          <span>校园虚拟社区</span>
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "teacher_home_ServiceSupport",
  data() {
    return {};
  },
  methods: {
    handleQA() {},
  },
};
</script>

<style lang="less" scoped>
.Teacher_Home_ServiceSupport {
  background: #fff;
  box-shadow: @shadow;
  height: calc(100vh - 557px);
  min-height: 350px;
  .stu-module-title {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 0 20px;
    .items {
      background: #f2f2f2;
      padding: 10px 0;
      margin-top: 20px;
      cursor: pointer;
      &:hover {
        p span,
        p i {
          color: @mainColor;
        }
      }
      p {
        width: 50%;
        margin: 0 auto;
        span {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      p {
        width: 140px !important;
      }
    }
  }
}
</style>