<template>
  <div class="Teacher_Home_Calendar">
    <div class="stu-module-title">日历</div>
    <div class="content">
      <el-calendar v-model="value"> </el-calendar>
    </div>
  </div>
</template>

<script>
export default {
  name: "teacher_home_calendar",
  data() {
    return {
      value: new Date(),
    };
  },
};
</script>

<style lang="less" scoped>
.Teacher_Home_Calendar {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: @shadow;
  .stu-module-title {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 0 20px;
  }
}
</style>
<style lang="less">
.Teacher_Home_Calendar {
  .content {
    .el-calendar__button-group {
      .el-button {
        padding: 4px 8px;
        span {
          font-size: 12px;
        }
      }
    }
    .el-calendar__header {
        padding: 12px 0;
    }
    .el-calendar-table .el-calendar-day {
      height: unset;
      text-align: center;
    }
  }
}
</style>