
<style scoped lang='less'>
.Teacher-Home {
  .row {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1200px) {
    .md24_marginBottom {
      margin-bottom: 20px;
    }
  }
}
</style>
<template>
  <div class="Teacher-Home">
    <!-- v-if="routeSwitch === home_route.HOME" -->
    <el-row :gutter="16" v-if="routeSwitch === home_route.HOME">
      <el-col :md="24" :lg="17" class="md24_marginBottom">
        <template v-if="queryRoot">
          <el-row :gutter="16" class="row">
				  
            <!-- 教师简介 -->
            <el-col :md="24" :lg="14" class="md24_marginBottom mobilebg">
              <HomeInfo :queryRoot="queryRoot" />
            </el-col>
			<el-col :md="24" :lg="14" class="md24_marginBottom pchide">
				<block v-for="(item, index) in menuList">
					<div :index="item.path" :key="index" @click="toMenu(item)" style="width: 85px;height: 65px;float: left;text-align: center;margin:5px 5px 5px 5px">
					  <i v-if="item.icon" :class="item.icon" style="width: 35px;margin: 0px auto;color: #307ef3;"></i>
					  <div v-else-if="item.svg" class="svg-div" style="width: 35px;margin: 0px auto;">
					    <svg-icon :name="item.svg" style="width: 30px; height:30px;color: #307ef3;"></svg-icon>
					  </div>
					  <span
					    slot="title" style="font-size: 12px;"
					    :style="!item.svg && !item.icon ? { paddingLeft: '20px' } : {}"
					    >{{ item.title }}</span
					  >
					  <!-- <el-badge
					    v-if="item.count && badge > 0"
					    :value="badge"
					    class="stu_badge"
					  ></el-badge> -->
					</div>
					</block>
			</el-col>
            <!-- 通知公告 -->
            <el-col :md="24" :lg="10">
              <Notice :queryRoot="queryRoot" />
            </el-col>
          </el-row>
          <!-- 课程 -->
          <el-row :gutter="16" class="mobilehide">
            <el-col :span="24">
              <Course :queryRoot="queryRoot" />
            </el-col>
          </el-row>
        </template>
        <no-root style="height: 800px; line-height: 800px" v-else />
      </el-col>

      <el-col :md="24" :lg="7" class="mobilehide">
        <Calendar />
        <ServiceSupport />
      </el-col>
    </el-row>

    <UserInfo v-if="routeSwitch === home_route.USERINFO" />
  </div>
</template>

<script>
import { TEACHER_HOME_ROUTE } from "@/tools/const";
import { COURSE_TYPE } from "@/tools/const";
import { GetUserMenuList } from "@/libs/api/user";
import HomeInfo from "./module/HomeInfo.vue";
import Course from "./module/Course.vue";
import Notice from "./module/Notice.vue";
import Calendar from "./module/Calendar.vue";
import ServiceSupport from "./module/ServiceSupport.vue";
import UserInfo from "./module/user/index.vue";
export default {
  name: "teacher-home",
  components: {
    HomeInfo,
    Notice,
    Course,
    Calendar,
    ServiceSupport,
    UserInfo,
  },
  data() {
    return {
		menuList: [],
		menuConfig: [
		  {
		    svg: "home",
		    title: "我的首页",
		    path: "/teacher/index",
		    name: "teacher-index",
		  },
		  {
		    svg: "announcement",
		    title: "教务公告",
		    path: "/teacher/notice",
		    name: "teacher-notice",
		    count: true,
		  },
		  {
		    svg: "user",
		    title: "我的课程",
		    path: "/teacher/course",
		    name: "course-index",
		  },
		  {
		    svg: "edit",
		    title: "在线作业",
		    path: "/teacher/work",
		    name: "work-index",
		  },
		  {
		    svg: "edit",
		    title: "在线作业批阅",
		    path: "/teacher/checkWork",
		    name: "checkWork-index",
		  },
		  {
		    svg: "edit",
		    title: "在线考试",
		    path: "/teacher/exam",
		    name: "exam-index",
		  },
		  {
		    svg: "edit",
		    title: "在线考试批阅",
		    path: "/teacher/checkExam",
		    name: "checkExam-index",
		  },
		  {
		    svg: "questions",
		    title: "在线答疑",
		    path: "/teacher/questions",
		    name: "questions-index",
		  },
		  {
		    svg: "cloud",
		    title: "我的网盘",
		    path: "/teacher/t/cloud",
		    name: "t-cloud-index",
		  },
		],
		menu: [],
		// 网盘
		cloud: [
		  {
		    svg: "cloud",
		    title: "我的网盘",
		    path: "/teacher/t/cloud",
		    name: "t-cloud",
		  },
		  {
		    svg: "recycle",
		    title: "我的回收站",
		    path: "/teacher/t/recycle",
		    name: "t-recycle",
		  },
		],
		// 普通课程
		course: [
		  {
		    code: 1,
		    svg: "",
		    title: "课程资源",
		    path: "/teacher/t/course/resources",
		    name: "t-course-resources",
		  },
		  {
		    code: 2,
		    svg: "",
		    title: "课程简介",
		    path: "/teacher/t/course/introduction",
		    name: "t-course-introduction",
		  },
		  {
		    code: 3,
		    svg: "",
		    title: "学习导航",
		    path: "/teacher/t/study/nav",
		    name: "t-study-nav",
		  },
		  {
		    code: 4,
		    svg: "",
		    title: "课程公告",
		    path: "/teacher/t/course/notice",
		    name: "t-course-notice",
		  },
		  {
		    code: 5,
		    svg: "",
		    title: "课程管理",
		    path: "/teacher/t/course/manage",
		    name: "t-course-manage",
		  },
		  {
		    code: 6,
		    svg: "",
		    title: "在线答疑",
		    path: "/teacher/t/question",
		    name: "t-question",
		  },
		  {
		    code: 7,
		    svg: "",
		    title: "题库管理",
		    path: "/teacher/t/answer/manage",
		    name: "t-answer-manage",
		  },
		  {
		    code: 8,
		    svg: "",
		    title: "试卷管理",
		    path: "/teacher/t/exampaper/manage",
		    name: "t-exampaper-manage",
		  },
		  {
		    code: 9,
		    svg: "",
		    title: "在线作业",
		    path: "/teacher/t/work/online",
		    name: "t-work-online",
		  },
		  {
		    code: 10,
		    svg: "",
		    title: "成绩管理",
		    path: "/teacher/t/result/manage",
		    name: "t-result-manage",
		  },
		  {
		    code: 11,
		    svg: "",
		    title: "在线考试",
		    path: "/teacher/t/exam/online",
		    name: "t-exam-online",
		  },
		  {
		    code: 12,
		    svg: "",
		    title: "菜单设置",
		    path: "/teacher/t/menu/set",
		    name: "t-menu-set",
		  },
		],
		// 毕业大作业
		work: [
		  {
		    svg: "",
		    title: "课程简介",
		    path: "/teacher/t/course/introduction",
		    name: "t-course-introduction",
		  },
		  {
		    svg: "",
		    title: "学习导航",
		    path: "/teacher/t/study/nav",
		    name: "t-study-nav",
		  },
		  {
		    svg: "",
		    title: "参考选题",
		    path: "/teacher/t/selection",
		    name: "t-selection",
		  },
		  {
		    svg: "",
		    title: "课程文件",
		    path: "/teacher/t/course/file",
		    name: "t-course-file",
		  },
		  {
		    svg: "",
		    title: "时间安排",
		    path: "/teacher/t/time/big/arrange",
		    name: "t-time-big-arrange",
		  },
		  {
		    svg: "",
		    title: "课程视频",
		    path: "/teacher/t/course/manage",
		    name: "t-course-manage",
		  },
		  {
		    svg: "",
		    title: "在线答疑",
		    path: "/teacher/t/question",
		    name: "t-question",
		  },
		  {
		    svg: "",
		    title: "审核大作业",
		    path: "/teacher/t/audit/work",
		    name: "t-audit-work",
		  },
		],
		// 毕业论文（设计）
		design: [
		  {
		    svg: "",
		    title: "课程简介",
		    path: "/teacher/t/course/introduction",
		    name: "t-course-introduction",
		  },
		  {
		    svg: "",
		    title: "学习导航",
		    path: "/teacher/t/study/nav",
		    name: "t-study-nav",
		  },
		  {
		    svg: "",
		    title: "参考选题",
		    path: "/teacher/t/selection",
		    name: "t-selection",
		  },
		  {
		    svg: "",
		    title: "课程文件",
		    path: "/teacher/t/course/file",
		    name: "t-course-file",
		  },
		  {
		    svg: "",
		    title: "时间安排",
		    path: "/teacher/t/time/arrange",
		    name: "t-time-arrange",
		  },
		  {
		    svg: "",
		    title: "课程视频",
		    path: "/teacher/t/course/manage",
		    name: "t-course-manage",
		  },
		  {
		    svg: "",
		    title: "在线答疑",
		    path: "/teacher/t/question",
		    name: "t-question",
		  },
		  {
		    svg: "",
		    title: "电子签名",
		    path: "/teacher/t/signature",
		    name: "t-signature",
		  },
		  {
		    svg: "",
		    title: "指导学生",
		    path: "/teacher/t/guide/student",
		    name: "t-guide-student",
		  },
		  {
		    svg: "",
		    title: "开题审核",
		    path: "/teacher/t/audit/report",
		    name: "t-audit-report",
		  },
		  {
		    svg: "",
		    title: "论文评审",
		    path: "/teacher/t/paper/review",
		    name: "t-paper-review",
		  },
		],
	};
  },
  mounted() {
  	this.getUserMenu();
  },
  computed: {
	  
	  menuSwitch: {
	    get() {
	      return this.$store.getters.getTeacherMenuSwitch;
	    },
	    set(v) {
	      this.$store.dispatch("set_teacher_menu_switch", v);
	    },
	  },
    routeSwitch() {
      return this.$store.getters.getTeacherRouteSwitch;
    },
    home_route() {
      return TEACHER_HOME_ROUTE;
    },
    queryRoot() {
      return this.$sysPerKey("eduTeacher:query");
    },
  },
  methods:{
	  toMenu(item){
	  		  console.log(item);
			  this.$router.push(item.path);
	  },
	  getUserMenu() {
	    this.loading = true;
	    GetUserMenuList()
	      .then((res) => {
			  console.log(res,"index res")
	        const resData = res.data.find((f) => f.menuType === 1);
	        const data = resData ? resData.sysMenuVos : [];
			console.log(data,"index data")
	        if (data.length) {
	          let menu = [];
	          let per = [];
	          this.menuConfig.forEach((m) => {
	            data.forEach((item) => {
	              if (m.title === item.menuName) {
	                menu.push({
	                  ...m,
	                  sysPermissions: item.sysPermissions,
	                });
	                per.push(...item.sysPermissions);
	              }
	            });
	          });
	          this.$store.dispatch("set_sys_per_teacher", per);
	          this.menu = menu;
	          this.loading = false;
	  
	          // 菜单切换
	          this.set_menu(this.menuSwitch);
	        }
	      })
	      .catch(() => {
	        this.loading = false;
	      });
	  },
	  set_menu(switchs, returns) {
	    let list;
	    if (switchs === COURSE_TYPE.T3) {
	      // 首页菜单
	      list = this.menu;
	    }
	    if (switchs === COURSE_TYPE.T0) {
	      // 普通课程
	      let keyName = this.routsKeyName + this.$store.getters.getTCourse.id;
	      let menuList = JSON.parse(localStorage.getItem(keyName));
	      if (menuList == null) {
	        list = this.course;
	      } else {
	        list = menuList;
	      }
	    }
	    if (switchs === COURSE_TYPE.T4) {
	      // 网盘
	      list = this.cloud;
	    }
	    // 毕业大作业
	    if (switchs === COURSE_TYPE.T2) {
	      list = this.work;
	    }
	    if (switchs === COURSE_TYPE.T1) {
	      // 毕业论文（设计）
	      list = this.design;
	    }
	    if (returns) {
	      return list;
	    }
	    this.menuList = [];
	    this.menuList = list;
	  console.log(this.menuList,"index menuList");
	    for (const item of this.menuList) {
	      if (this.$route.path.indexOf(item.path) > -1) {
	        this.activeName = item.path;
	        break;
	      }
	    }
	  }
  }
};
</script>
