<style scoped lang="less">
.Teacher_Home_Info {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  min-height: 200px;

  > .el-row:first-child {
    border-bottom: 1px solid @borderBottomColor;
    padding-bottom: 25px;
    margin-top: 15px;
  }

  .teacher_name {
    display: inline-block;
    height: 67px;
    vertical-align: top;
    margin-left: 10px;
    position: relative;

    .teacher_name_p {
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 5px;
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .workNo {
      color: #999;
      position: relative;
      top: 40px;
      left: 0;
    }
  }

  .teacher_info_btn {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    min-width: 113px;
    height: 56px;
    cursor: pointer;
    background: rgba(48, 126, 243, 0.07);

    .svg-group {
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      margin-right: 10px;

      .svg-div {
        margin: 0 auto;
        width: 18px;
        height: 18px;
      }
    }

    .blue {
      background-image: linear-gradient(#6bc1ff, #0887e7);
    }
  }

  .info_ul {
    li {
      margin-top: 16px;
      padding-left: 22px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 4px;
        top: 50%;
        margin-top: -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #83b2f8;
      }

      span:first-child {
        color: #999;
      }
    }
  }
}
</style>
<template>
  <div class="Teacher_Home_Info mobilebg">
    <el-row type="flex" align="middle" justify="space-between" class="mobilebg">
      <el-col :span="16">
        <el-avatar
          :size="65"
          :src="data.headerUrl"
          class="headerImg"
          shape="square"
        >
          <img :src="noneUrl" />
        </el-avatar>
        <div class="teacher_name">
          <el-tooltip effect="dark" :content="data.name" placement="top">
            <p class="teacher_name_p">{{ data.name }}</p>
          </el-tooltip>
          <p class="workNo">工号：{{ data.jobNo }}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <div @click="handleItems()" class="teacher_info_btn">
          <div class="svg-group blue">
            <div class="svg-div">
              <svg-icon name="user-btn"></svg-icon>
            </div>
          </div>
          <div>个人信息</div>
        </div>
      </el-col>
    </el-row>
    <ul class="info_ul mobilebg">
      <li>
        <span>上次登录时间：</span>
        <span>{{ data.lastLoginTime }}</span>
      </li>
      <li>
        <span>共计登录次数：</span>
        <span>{{ data.loginCount }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { TEACHER_HOME_ROUTE } from "@/tools/const";
import { QueryTeacherIndexData } from "@/libs/api/teacher/user";
export default {
  name: "Teacher_Home_Info",
  props: ["queryRoot"],
  data() {
    return {
      data: {},
      noneUrl:
        "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png",
      user: {
        headerUrl: "",
        name: "",
        workNo: "",
        lastLoginTime: "",
        totalLoginCount: 0,
      },
    };
  },
  mounted() {
    this.queryRoot && this.query();
	
  },
  methods: {
    handleItems() {
      this.$store.dispatch(
        "set_teacher_route_switch",
        TEACHER_HOME_ROUTE.USERINFO
      );
    },
    query() {
      QueryTeacherIndexData().then((res) => {
        this.data = res.data;
      });
	  
    },
	
  },
};
</script>